


.css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px 15px !important;
}


.MuiOutlinedInput-input.MuiInputBase-input.css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 16.5px 14px !important;
}


.css-1irsfyf-MuiGrid-root>.MuiGrid-item {
    padding-top: 10px !important;
}